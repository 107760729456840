import { temporal } from '@pretto/bricks/core/utility/temporal'

import { LANDING_PAGE_SEO } from '@pretto/website/src/config/pagesUrls'
import { HeroRates } from '@pretto/website/src/templates/blocs/rows/heroRates/components/HeroRates'
import { Border } from '@pretto/website/src/templates/blocs/rows/rowRates/types/border.type'
import { RatesPayload } from '@pretto/website/src/templates/blocs/types/RatesPayload.type'
import { ratesProfileRegion } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesProfileRegion'
import { useRatesForm } from '@pretto/website/src/templates/tauxImmobilier/lib/useRatesForm'

type HeroRatesContainerProps = {
  ctaLabel: string
  ctaUrl: string
  help: string
  isColored?: boolean
  mortgageDuration?: string
  mortgagorsNumber?: string
  mortgagorsWages?: string
  propertyPrice?: number
  propertyRegion?: string
  rates: RatesPayload
  text?: string
  title: string
}

export const HeroRatesContainer: React.FC<HeroRatesContainerProps> = ({
  ctaLabel,
  ctaUrl,
  help,
  mortgageDuration,
  mortgagorsNumber,
  mortgagorsWages,
  propertyPrice,
  propertyRegion,
  rates,
  text,
  title,
  ...props
}) => {
  const { values, form } = useRatesForm({
    preSelectedValues: {
      goodPrice: propertyPrice || undefined,
      mortgagors: mortgagorsNumber ? parseInt(mortgagorsNumber, 10) : undefined,
      region: propertyRegion,
      wagesIndex: mortgagorsWages ? parseInt(mortgagorsWages, 10) : undefined,
    },
    rates: rates.marketRatesProfileLatest,
  })

  const ratesToUse = ratesProfileRegion(rates.marketRatesProfileRegionLatest, {
    ...values,
    defaultDuration: mortgageDuration ? parseInt(mortgageDuration, 10) : undefined,
  })

  const rowProps = {
    colors: { backgroundColor: 'neutral4', formColor: 'neutral3', border: Border.SHADOW },
    isHero: true,
    ratesProps: {
      cta: ctaLabel,
      form,
      help,
      isReversed: true,
      rates: ratesToUse,
      url: ctaUrl ?? LANDING_PAGE_SEO,
    },
    text: text ?? (
      <span>
        Taux du marché observés par Pretto, mis à jour le <strong>{temporal().format('LL')}</strong>
      </span>
    ),
    title,
  }

  return <HeroRates {...rowProps} {...props} />
}
